<template>
  <div>
    <button @click="isOpen = true" type="button" class="bg-slate-700 rounded-full p-2 lg:p-1.5">
      <img :src="`/assets/flag/${site.handle}.svg`" :alt="site.name" class="size-6">
    </button>

    <Dialog :open="isOpen" @close="isOpen = false" class="relative z-50">
      <div class="fixed inset-0 bg-black/30" aria-hidden="true" />

      <div class="fixed inset-0 flex items-center justify-center p-4">
        <DialogPanel class="w-full max-w-sm bg-white p-6">
          <DialogTitle class="text-xl font-semibold mb-4">Choose a region</DialogTitle>
          
          <div class="space-y-3">
            <a :href="ukUrl" class="flex items-center border border-slate-200 hover:border-gold hover:ring-1 hover:ring-gold p-3">
              <img :src="`/assets/flag/default.svg`" alt="Conflict International" class="shrink-0 size-6">
              <div class="text-sm font-medium ml-2">Conflict International</div>
            </a>
            <a :href="usaUrl" class="flex items-center border border-slate-200 hover:border-gold hover:ring-1 hover:ring-gold p-3">
              <img :src="`/assets/flag/usa.svg`" alt="Conflict International USA" class="shrink-0 size-6">
              <div class="text-sm font-medium ml-2">Conflict International USA</div>
            </a>
            <a :href="advisoryUrl" class="flex items-center border border-slate-200 hover:border-gold hover:ring-1 hover:ring-gold p-3">
              <img :src="`/assets/flag/advisory.svg`" alt="Conflict Advisory Group" class="shrink-0 size-6">
              <div class="text-sm font-medium ml-2">Conflict Advisory Group</div>
            </a>
          </div>

          <button @click="isOpen = false" type="button" tabindex="1" class="btn w-full bg-none bg-slate-200 text-navy rounded-none focus:outline-none py-3 mt-6">Cancel</button>
        </DialogPanel>
      </div>
    </Dialog>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import { Dialog, DialogPanel, DialogTitle } from '@headlessui/vue'

const props = defineProps({
  currentSite: {
    type: String,
    required: true
  },
  ukUrl: {
    type: String,
    required: true
  },
  usaUrl: {
    type: String,
    required: true
  },  
  advisoryUrl: {
    type: String,
    required: true
  }
});

const site = computed(() => JSON.parse(props.currentSite));
const isOpen = ref(false)
</script>
