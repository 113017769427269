<template>
  <div>
    <button @click="openMenu" type="button" class="flex items-center w-full hover:text-gold text-xs font-medium uppercase tracking-widest space-x-2 py-4">
      <svg class="flex-none size-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" /></svg>
      <span>Menu</span>
    </button>

    <TransitionRoot :show="isOpen" as="template">
      <Dialog @close="closeMenu" class="relative z-10">
        <TransitionChild as="template" enter="duration-500" enter-from="opacity-0" enter-to="opacity-100" leave="duration-500" leave-from="opacity-100" leave-to="opacity-0" >
          <div class="fixed inset-0 bg-black/30" aria-hidden="true" />
        </TransitionChild>

        <div class="fixed inset-0 overflow-hidden">
          <div class="absolute inset-0 overflow-hidden">
            <div class="pointer-events-none fixed inset-y-0 left-0 flex max-w-full">
              <TransitionChild as="template" enter="duration-500" enter-from="-translate-x-full" enter-to="translate-x-0" leave="duration-500" leave-from="translate-x-0" leave-to="-translate-x-full">
                <DialogPanel class="pointer-events-auto w-screen max-w-sm">
                  <div class="h-full bg-navy text-white border-t-4 border-gold overflow-y-scroll">
                    <div class="flex justify-between p-4">
                      <a href="/" class="inline-flex">
                        <img :src="`/assets/logo/${site.handle}.svg`" :alt="site.name" width="800" height="228" class="w-auto h-10">
                      </a>
                      <button @click="closeMenu" type="button" tabindex="1" class="text-slate-500 focus:outline-none">
                        <span class="sr-only">Close Menu</span>
                        <svg class="size-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path d="M6.28 5.22a.75.75 0 0 0-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 1 0 1.06 1.06L10 11.06l3.72 3.72a.75.75 0 1 0 1.06-1.06L11.06 10l3.72-3.72a.75.75 0 0 0-1.06-1.06L10 8.94 6.28 5.22Z" /></svg>
                      </button>
                    </div>

                    <nav class="divide-y divide-slate-700 border-y border-slate-700">
                      <a v-for="item in navItems" :key="item.name" :href="item.uri" class="block hover:text-gold text-xs font-medium uppercase tracking-widest px-4 py-5">{{ item.name }}</a>
                    </nav>
                  </div>
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue'

const isOpen = ref(false)

const props = defineProps({
  currentSite: {
    type: String,
    required: true
  }
});

const site = computed(() => JSON.parse(props.currentSite));

const navItems = [
  { name: 'Home', uri: '/' },
  { name: 'Services', uri: '/services' },
  { name: 'Team', uri: '/team' },
  { name: 'News', uri: '/news' },
  { name: 'Case Studies', uri: '/case-studies' },
  { name: 'About', uri: '/about' },
  { name: 'Contact', uri: '/contact' },
]

function openMenu() {
  isOpen.value = true
}

function closeMenu() {
  isOpen.value = false
}
</script>
